import {AccountCircle, Login, Logout} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Box, Container, IconButton, Toolbar, Typography,} from "@mui/material";
import {withStyles} from "@mui/styles";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";

import Candidate from "./Candidate";
import Electors from "./Electors";
import Identification from "./Identification";
import Register from "./Register";

import {X_AUTH_TOKEN, X_DATA} from "./utils";

window.AudioContext = window.AudioContext || window.webkitAudioContext;
navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia;
window.URL = window.URL || window.webkitURL;

axios.defaults.headers.common[X_AUTH_TOKEN] =
    localStorage.getItem(X_AUTH_TOKEN);

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    form: {
        height: 400,
    },
    user: {
        width: "100%",
    },
    record: {
        width: 50,
        cursor: "pointer",
    },
    red: {
        color: "red",
    },
    green: {
        color: "green",
    },
    candidate: {
        width: 200,
    },
    logout: {
        float: "right",
        alignItems: "center",
    },
    flex: {
        flex: 1,
    },
});

function App(props) {
    const navigate = useNavigate();
    const token = localStorage.getItem(X_AUTH_TOKEN);
    const authData = JSON.parse(localStorage.getItem(X_DATA));
    const isAuth = Boolean(token);
    const [auth, setAuth] = useState(false);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        setAuth(isAuth);
    }, [isAuth]);

    const onLogout = (event) => {
        event.preventDefault();
        localStorage.removeItem(X_AUTH_TOKEN);
        localStorage.removeItem(X_DATA);
        setAuth(false);
        setInfo(null);
    };

    const onLogin = (response) => {
        let token = response.headers[X_AUTH_TOKEN];
        console.log('token', JSON.stringify(token));
        localStorage.setItem(X_AUTH_TOKEN, token);
        setAuth(true);
        axios.defaults.headers.common[X_AUTH_TOKEN] = token;
        let data = response.data;
        localStorage.setItem(X_DATA, JSON.stringify(data));
        setInfo(data);
        navigate("/");
    };

    const doRegister = (e) => {
        navigate("/register");
    };

    const doLogin = (e) => {
        navigate("/login");
    };

    return (
        <div>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        component="a"
                        noWrap
                        href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                        }}
                        sx={{
                            mr: 2,
                            display: "flex",
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            flexGrow: 1,
                        }}
                    >
                        Kalata
                    </Typography>
                    {auth === true && (
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={onLogout}
                        >
                            <Logout/>
                        </IconButton>
                    )}
                    {auth === false && (
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={doRegister}
                        >
                            <AccountCircle/>
                        </IconButton>
                    )}
                    {auth === false && (
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={doLogin}
                        >
                            <Login/>
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Container>
                <Box mt={5}>
                    <Routes>
                        <Route
                            path={"/"}
                            element={
                                isAuth ? (
                                    <Electors
                                        {...props}
                                        navigate={navigate}
                                        info={info || authData}
                                    />
                                ) : (
                                    <Navigate to="/login"/>
                                )
                            }
                        />
                        <Route
                            path={"/candidates"}
                            element={<Candidate {...props}
                                                navigate={navigate}/>}
                        />
                        <Route
                            path={"/login"}
                            element={
                                <Identification
                                    {...props}
                                    navigate={navigate}
                                    url="login"
                                    onLogin={onLogin}
                                    title={
                                        <strong>
                                            For login please record your
                                            passphrase by pressing on the
                                            microphone and press again to
                                            submit.
                                        </strong>
                                    }
                                />
                            }
                        />
                        <Route
                            path={"/register"}
                            element={
                                <Register
                                    {...props}
                                    navigate={navigate}
                                    url="register"
                                    firstName
                                    lastName
                                    threshold={1.0}
                                    onLogin={onLogin}
                                    title={
                                        <strong>
                                            For registration please record your
                                            passphrase by pressing on the
                                            microphone and press again to
                                            submit.
                                        </strong>
                                    }
                                />
                            }
                        />
                    </Routes>
                </Box>
            </Container>
        </div>
    );
}

export default withStyles(useStyles)(App);
