import { Box, Divider, Grid, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import React, { Fragment } from "react";
import { X_AUTH_TOKEN, X_DATA } from "./utils";

import Elector from "./Elector";
import ElectorForm from "./ElectorForm";

class Electors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            votePlace: "",
            electors: [],
            selected: null,
            places: [],
            selectedTab: 1,
        };
        this.handleVotePlace = this.handleVotePlace.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onElectorAdded = this.onElectorAdded.bind(this);
    }

    handleChange(e) {
        console.log("elector", e);
        let electors = this.state.electors.map((it) => {
            if (it.id === e?.id) {
                return this.state.selected;
            }
            return it;
        });
        this.setState({ electors: electors });
    }

    handleVotePlace(e) {
        e.preventDefault();
        let votePlace = e.target.value;
        console.log("votePlace", votePlace);
        this.setState({ votePlace: votePlace }, () => {
            this.loadElectors();
        });
    }

    handleTab(e, newValue) {
        this.setState({ selectedTab: newValue, selected: null });
    }

    async loadPlaces() {
        try {
            let response = await axios.get("/api/places");
            let data = response.data;
            console.log("vote_places:data", data);
            let info = this.props.info;
            console.log("info", info);
            if (info?.admin) {
                let place_codes = JSON.parse(info.admin.place_codes) || [];
                data = data.filter(
                    (it) =>
                        place_codes.length === 0 ||
                        place_codes.includes(it.code)
                );
            }
            this.setState({ places: data });
        } catch (e) {
            let response = e.response;
            this.redirectToLogin(response);
            console.log("loadPlaces:response", response);
        }
    }

    async loadElectors() {
        try {
            let response = await axios.get("/api/electors", {
                params: { votePlace: this.state.votePlace },
            });
            let data = response.data || [];
            let selected = data.find((it) => it.id === this.state.selected?.id);
            console.log("data", data);
            this.setState({ electors: data, selected: selected });
        } catch (e) {
            let response = e.response;
            console.log("loadElectors:response", response);
            this.redirectToLogin(response);
        }
    }

    redirectToLogin(response) {
        if (response.status === 401) {
            localStorage.removeItem(X_AUTH_TOKEN);
            localStorage.removeItem(X_DATA);
            axios.defaults.headers.common[X_AUTH_TOKEN] = null;
            this.props.navigate("login");
        }
    }

    async componentDidMount() {
        this.loadPlaces();
        this.loadElectors();
    }

    onElectorAdded(response) {
        console.log('response', response);
        this.loadElectors();
    }

    render() {
        let filters = [
            (it) => true,
            (it) => !it.enrolled,
            (it) => it.enrolled && !it.voted,
            (it) => it.voted,
        ];
        let electors = this.state.electors;
        let places =
            JSON.parse(this.props.info?.admin?.place_codes || "[]") || [];
        console.log("this.props.info.admin.place_codes", places);
        if (places.length > 0) {
            electors = electors.filter((it) => places.includes(it.vote_place));
        }
        let TabItem = (filter) => {
            return (
                <Grid container>
                    <Grid
                        item
                        md={3}
                        xs={12}
                        component="nav"
                        sx={{
                            //width: { sm: drawerWidth },
                            flexShrink: { sm: 0 },
                            overflow: "auto",
                            maxHeight: 750,
                        }}
                        aria-label="mailbox folders"
                    >
                        <List>
                            {electors.filter(filter).map((elector) => (
                                <Fragment key={elector.id}>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            selected={
                                                elector.id ===
                                                this.state.selected?.id
                                            }
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    selected: elector,
                                                });
                                            }}
                                        >
                                            <ListItemText
                                                primary={`${elector.last_name} ${elector.first_name} (${elector.phone_number})`}
                                                primaryTypographyProps={{
                                                    color: elector.voted
                                                        ? "green"
                                                        : elector.enrolled
                                                        ? "blue"
                                                        : null,
                                                }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </Grid>
                    <Grid
                        item
                        md={9}
                        xs={12}
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 3,
                            //width: { sm: `calc(100% - ${drawerWidth}px)` },
                        }}
                    >
                        {this.state.selected && (
                            <Elector
                                {...this.props}
                                onDelete={this.onElectorAdded}
                                onReset={this.onElectorAdded}
                                elector={this.state.selected}
                                handleChange={this.handleChange}
                            />
                        )}
                    </Grid>
                </Grid>
            );
        };
        return (
            <Box sx={{ display: "flex" }}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Vote place
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.votePlace}
                                label="Vote place"
                                onChange={this.handleVotePlace}
                            >
                                <MenuItem value="">All</MenuItem>
                                {this.state.places.map((place) => (
                                    <MenuItem key={place.id} value={place.code}>
                                        {place.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={this.handleTab}
                            aria-label="wrapped label tabs"
                        >
                            <Tab
                                value={0}
                                label={`All (${
                                    electors.filter(filters[0]).length
                                })`}
                            />
                            <Tab
                                value={1}
                                label={`To Enroll (${
                                    electors.filter(filters[1]).length
                                })`}
                            />
                            <Tab
                                value={2}
                                label={`Enrolled (${
                                    electors.filter(filters[2]).length
                                })`}
                                style={{ color: "blue" }}
                            />
                            <Tab
                                value={3}
                                label={`Voted (${
                                    electors.filter(filters[3]).length
                                })`}
                                style={{ color: "green" }}
                            />
                            <Tab
                                value={4}
                                label={"Add New Elector"}
                            />
                        </Tabs>
                    </Box>
                    { this.state.selectedTab < 4 && TabItem(filters[this.state.selectedTab])}
                    {this.state.selectedTab === 4 && <ElectorForm votePlace={this.state.votePlace} onSuccess={this.onElectorAdded}/>}
                </Grid>
            </Box>
        );
    }
}

export default Electors;
