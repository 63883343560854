
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";


export const X_AUTH_TOKEN = "x-auth-token";
export const X_DATA = "x-data";
export const VOTE_ENDED_SECONDS = 1;

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));