import React from "react";
import axios from "axios";

import {
    Button,
    Grid,
    TextField,
} from "@mui/material";

//import VoiceRecorder from "./VoiceRecorder"

//const LOGIN_SECONDS = 1;

class Identification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            recording: false,
            user: "",
            phoneNumber: "",
            password: "",
            message: "",
            error: false,
            speaking: false,
        };
        this.sendData = this.sendData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        //this.onRecord = this.onRecord.bind(this);
        //this.onStateChanged = this.onStateChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        //this.onMean = this.onMean.bind(this);
        //this.isValid = this.isValid.bind(this);
        //this.voiceRecorder = React.createRef();
        this.jobId = -1;
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value});
    }

    // onRecord(blob) {
    //     this.setState({file: blob}, () => {
    //         if (Boolean(this.state.user) && this.state.file !== null) {
    //             this.sendData().then(() => {
    //                 console.log("Done.");
    //             });
    //         }
    //     });
    // }

    // onMean(means) {
    //     const mean = means[0];
    //     console.log("mean", mean);
    //     const threshold = this.props.threshold || 1.0;
    //     if (this.state.speaking && mean < threshold) {
    //         console.log("Stop speaking...");
    //         if (this.jobId < 0) {
    //             this.jobId = window.setTimeout(() => {
    //                 this.voiceRecorder.current.handleStopRecord();
    //             }, LOGIN_SECONDS * 1000);
    //         }
    //         this.setState({speaking: false, message: "Not speaking..."});
    //     } else if (!this.state.speaking && mean >= threshold) {
    //         console.log("Keep speaking...");
    //         if (this.jobId > 0) {
    //             window.clearTimeout(this.jobId);
    //             this.jobId = -1;
    //         }
    //         this.setState({speaking: true, message: "Speaking..."});
    //     }
    // }

    // isValid(means) {
    //     const mean = means[0];
    //     const threshold = this.props.threshold || 1.0;
    //     return mean > threshold;
    // }

    // onStateChanged(event) {
    //     console.log("recorder state : ", event);
    //     this.setState(event);
    // }

    async sendData() {
        let fd = new FormData();
        fd.append("user", `${this.state.phoneNumber}`);
        fd.append("password", `${this.state.password}`);
        // fd.append("file", this.state.file);
        try {
            let response = await axios.request({
                url: `/api/${this.props.url}`,
                method: "POST",
                data: fd,
                headers: {"Content-Type": "multipart/form-data"},
            });
            if (typeof this.props.onLogin === "function") {
                this.props.onLogin(response);
            }
            const {message} = response.data;
            this.setState({message: message, error: null});
        } catch (e) {
            console.log(e.response);
            this.setState({error: true, message: e.response.data.message});
        }
    }

    onSubmit(event) {
        event.preventDefault();
        this.sendData();
    }

    isValidate() {
        return Boolean(this.state.phoneNumber) && Boolean(this.state.password)
    }

    render() {
        const {classes} = this.props;
        return (
            <form>
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    className={classes.form}
                >
                    <Grid item m={2}>
                        {this.props.title}
                    </Grid>
                    <Grid item m={1} minWidth={300}>
                        <TextField
                            className={classes.user}
                            id="user"
                            name="phoneNumber"
                            label="Phone"
                            type="tel"
                            autoComplete={"mobile"}
                            required={true}
                            value={this.state.phoneNumber}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    <Grid item m={1} minWidth={300}>
                        <TextField
                            className={classes.user}
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            required={true}
                            value={this.state.password}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    <Button
                        onClick={this.onSubmit}
                        disabled={!this.isValidate()}
                    >
                        Login
                    </Button>
                    {/*<Grid item>*/}
                    {/*    <VoiceRecorder*/}
                    {/*        ref={this.voiceRecorder}*/}
                    {/*        auto={false}*/}
                    {/*        disabled={!Boolean(this.state.user)}*/}
                    {/*        classes={classes}*/}
                    {/*        onRecord={this.onRecord}*/}
                    {/*        onStateChanged={this.onStateChanged}*/}
                    {/*        onMean={this.onMean}*/}
                    {/*        isValid={this.isValid}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item>*/}
                    {/*    {this.state.recording ? (*/}
                    {/*        <strong>Recording...</strong>*/}
                    {/*    ) : null}*/}
                    {/*</Grid>*/}
                    <Grid
                        item
                        className={
                            this.state.error ? classes.red : classes.green
                        }
                    >
                        {this.state.message}
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default Identification;