import React from "react";
import {
    Grid,
} from "@mui/material";

import axios from "axios";
import VoiceRecorder from "./VoiceRecorder";

// soundiata keita, kankou moussa, sonny Ali ber, askia Mohamed, el hadj oumar tall et Samory toure
const candidates = [
    {
        name: "Soundiata Keita",
        image: "",
    },
    {
        name: "Soumaworo Kanté",
        image: "",
    },
    {
        name: "Kankou Moussa",
        image: "",
    },
    {
        name: "Sonny Ali Ber",
        image: "",
    },
    {
        name: "Askia Mohamed",
        image: "",
    },
    {
        name: "El hadj Oumar Tall",
        image: "",
    },
    {
        name: "Samory Toure",
        image: "",
    },
];

class Candidate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            recording: false,
            message: "",
            error: false,
        };
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.restartRecord = this.restartRecord.bind(this);
        this.onRecord = this.onRecord.bind(this);
        this.voiceRecorder = React.createRef();
    }

    onNext() {
        let length = candidates.length;
        if (this.state.index + 1 < length) {
            this.restartRecord(() => {
                let length = candidates.length;
                return (this.state.index + 1) % length;
            });
        } else {
            this.setState({ index: 0 });
        }
    }

    restartRecord(callback) {
        if (this.state.recording) {
            this.voiceRecorder.current.handleStopRecord();
        }
        let index = this.state.index;
        if (typeof callback === "function") {
            index = callback();
        }
        this.setState({ index }, () => {
            this.voiceRecorder.current.handleStartRecord();
        });
    }

    onPrevious() {
        this.restartRecord(() => {
            let length = candidates.length;
            return (this.state.index - 1 + length) % length;
        });
    }

    async onRecord(blob) {
        const candidate = candidates[this.state.index];
        let fd = new FormData();
        fd.append("file", blob);
        try {
            let response = await axios.request({
                url: `/record/${candidate.name}`,
                method: "POST",
                data: fd,
                headers: { "Content-Type": "multipart/form-data" },
            });
            this.setState({ message: response.data, error: false }, () => {
                this.onNext();
            });
        } catch (e) {
            console.log(e.response);
            this.setState({ error: true, message: e.response.data });
        }
    }

    render() {
        const { classes } = this.props;
        const candidate = candidates[this.state.index];
        console.log("candidate", candidate);
        return (
            <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                className={classes.form}
            >
                <Grid>
                    <strong>
                        Please click on the microphone and record the name (
                        {candidate.name}).
                    </strong>
                </Grid>
                {/*<Grid item>
                <img src={candidate.image}
                     alt={candidate.name}
                     className={classes.candidate}/>
            </Grid>*/}
                <Grid item m={5}>
                    <strong style={{ fontSize: 32 }}>{candidate.name}</strong>
                </Grid>
                <Grid>
                    <VoiceRecorder
                        classes={classes}
                        ref={this.voiceRecorder}
                        onRecord={this.onRecord}
                        onStateChanged={(e) => this.setState(e)}
                    />
                </Grid>
                <Grid item>
                    {this.state.recording ? (
                        <strong>Recording...</strong>
                    ) : null}
                </Grid>
                <Grid item className={this.state.error ? classes.red : null}>
                    {this.state.message}
                </Grid>
                {/*<Grid item>
                <Grid container
                      alignItems="center"
                      justify="center"
                      direction={"row"}>
                    <Grid item>
                        <Button onClick={this.onPrevious}>Previous</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={this.onNext}>Next</Button>
                    </Grid>
                </Grid>
            </Grid>*/}
            </Grid>
        );
    }
}

export default Candidate;