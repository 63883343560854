import React, {Fragment} from "react";
import {Box, Button, Grid, IconButton, Input, TextField, Typography,} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Phone from "@mui/icons-material/Phone";

import RequestSender from "./RequestSender";
import VoiceRecorder from "./VoiceRecorder";

import {Item, VOTE_ENDED_SECONDS} from "./utils"
import axios from "axios";

class Elector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            recording: false,
            pause: false,
            voting: false,
            user: "",
            message: "",
            error: false,
            url: "register",
        };
        this.voiceRecorder = React.createRef();
        this.sender = React.createRef();
        this.onRecord = this.onRecord.bind(this);
        this.onStateChanged = this.onStateChanged.bind(this);
        this.onEnroll = this.onEnroll.bind(this);
        this.onAuth = this.onAuth.bind(this);
        this.onVote = this.onVote.bind(this);
        this.autoVote = this.autoVote.bind(this);
        this.onMean = this.onMean.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onReset = this.onReset.bind(this);
        this.votingJob = -1;
        this.speaking = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.elector?.id !== this.props.elector?.id) {
            this.setState({file: null, message: ""});
        }
    }

    onRecord(blob) {
        this.setState({file: blob}, () => {
            console.log("File set...", blob);
            if (this.state.voting) {
                this.autoVote();
            }
        });
        /*this.setState({ file: blob }, () => {
            if (Boolean(this.state.user) && this.state.file !== null) {
                this.sender.current.sendData().then(() => {
                    console.log("Done.");
                });
            }
        });*/
    }

    onMean(means) {
        let mean = means[0];
        const silence = 1;
        this.speaking = mean > silence;

        if (this.state.voting) {
            if (!this.speaking && this.votingJob === -1) {
                this.votingJob = window.setTimeout(() => {
                    this.voiceRecorder.current.handleStopRecord();
                }, VOTE_ENDED_SECONDS * 1000);
            } else if (this.speaking) {
                if (this.votingJob > -1) {
                    window.clearTimeout(this.votingJob);
                    this.votingJob = -1;
                }
            }
        }
        console.log("mean", mean);
    }

    onStateChanged(event) {
        console.log("recorder state : ", event);
        this.setState(event);
    }

    onSuccess(response) {
        const {message} = response.data;
        this.setState({error: false, message: message});
    }

    onError(response) {
        console.log(response);
        this.setState({error: true, message: response.data.message});
    }

    isDisabled() {
        return (
            !Boolean(this.props.elector.phone_number) ||
            this.state.file === null ||
            this.state.recording ||
            this.state.voting ||
            this.props.elector?.voted === 1
        );
    }

    disableEnrol() {
        return this.isDisabled()
            //|| this.props.elector?.enrolled === 1
            || this.props.elector?.authenticated === 1;
    }

    disableVote() {
        return (
            this.state.voting ||
            this.props.elector?.voted === 1 ||
            this.props.elector?.enrolled !== 1 ||
            !this.props.elector?.authenticated
        );
    }

    disableAuth() {
        return (
            this.isDisabled() ||
            !this.props.elector?.enrolled ||
            this.props.elector?.authenticated
        );
    }

    onEnroll(event) {
        event.preventDefault();
        this.setState({url: "enroll"}, () => {
            this.sender.current.sendData().then(() => {
                console.log("Enroll Done.");
                if (!this.state.error) {
                    this.props.elector.enrolled = 1;
                    this.sendChange()
                    this.setState({file: null});
                }
            });
        });
    }

    onAuth(event) {
        event.preventDefault();
        this.setState({url: "auth"}, () => {
            this.sender.current.sendData().then(() => {
                console.log("Auth Done.");
                if (!this.state.error) {
                    this.props.elector.authenticated = true;
                    this.sendChange()
                    this.setState({file: null});
                }
            });
        });
    }

    onVote(event) {
        event.preventDefault();
        this.setState({voting: true}, () =>
            this.voiceRecorder.current.handleStartRecord()
        );
    }

    sendChange() {
        if (typeof this.props.handleChange === 'function') {
            this.props.handleChange(this.props.elector);
        }
    }

    autoVote() {
        if (this.state.file != null) {
            this.setState({url: "votes", recording: false}, () => {
                this.sender.current.sendData().then(() => {
                    console.log("Vote Done.");
                    this.props.elector.voted = 1;
                    this.sendChange()
                    this.setState({file: null, voting: false});
                    if (this.votingJob > -1) {
                        window.clearTimeout(this.votingJob);
                        this.votingJob = -1;
                    }
                });
            });
        } else {
            console.log("File is not set...");
        }
    }

    onDelete(event) {
        let confirm = window.confirm(`You really want to delete ${event.first_name} ${event.last_name}(${event.id})`);
        if (confirm) {
            axios.delete(`/api/electors/${event.id}`)
                .then(response => {
                    console.log("Item deleted", response);
                    if (typeof this.props.onDelete === "function") {
                        this.props.onDelete(response.data);
                    }
                })
                .catch(response => {
                    console.log("Error occurred", response);
                })
        }
    }

    onReset(event) {
        let confirm = window.confirm(`You really want to reset ${event.first_name} ${event.last_name}(${event.id})`);
        if (confirm) {
            axios.patch(`/api/electors/reset/${event.id}`)
                .then(response => {
                    console.log("Item deleted", response);
                    if (typeof this.props.onDelete === "function") {
                        this.props.onReset(response.data);
                    }
                })
                .catch(response => {
                    console.log("Error occurred", response);
                });
        }
    }

    render() {
        const {elector, classes} = this.props;
        return (
            <Box sx={{display: "flex", flexWrap: "wrap"}}>
                <RequestSender
                    ref={this.sender}
                    url={this.state.url}
                    user={`${elector.phone_number}`}
                    force={true}
                    file={this.state.file}
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                />

                <Grid container>

                    <Grid item md={6} xs={6} p={1}>
                        <Item>
                            <Button
                                onClick={(e) => this.onDelete(elector)}
                                disabled={!Boolean(elector?.id)}
                                color="error"
                            >
                                Delete
                            </Button>
                        </Item>
                    </Grid>
                    {<Grid item md={6} xs={6} p={1}>
                            <Item>
                                <Button
                                    onClick={(e) => this.onReset(elector)}
                                    disabled={!Boolean(elector?.id) || !(elector.enrolled || elector.voted)}
                                    color="warning"
                                >
                                    Reset
                                </Button>
                            </Item>
                        </Grid>}
                    <Grid item md={6} p={1}>
                        <Item>
                            <TextField
                                label="Prénom"
                                disabled
                                fullWidth
                                value={elector.first_name}
                                variant="standard"
                            />
                        </Item>
                    </Grid>
                    <Grid item md={6} p={1}>
                        <Item>
                            <TextField
                                label="Nom"
                                disabled
                                fullWidth
                                value={elector.last_name}
                                variant="standard"
                            />
                        </Item>
                    </Grid>
                    <Grid item md={12} p={1}>
                        <Item>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="standard-adornment-phone">
                                    Téléphone
                                </InputLabel>
                                <Input
                                    id="standard-adornment-phone"
                                    label="Téléphone"
                                    disabled
                                    fullWidth
                                    value={elector.phone_number}
                                    variant="standard"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle phone visibility"
                                                onClick={() => {
                                                    window.open(
                                                        `tel:${elector.phone_number}`
                                                    );
                                                }}
                                                edge="end"
                                            >
                                                <Phone/>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Item>
                    </Grid>
                    {Boolean(elector.father_name) && (
                        <Grid item md={6} p={1}>
                            <Item>
                                <TextField
                                    label="Père"
                                    disabled
                                    fullWidth
                                    value={elector.father_name}
                                    variant="standard"
                                />
                            </Item>
                        </Grid>
                    )}
                    {Boolean(elector.mother_name) && (
                        <Grid item md={6} p={1}>
                            <Item>
                                <TextField
                                    label="Mère"
                                    disabled
                                    fullWidth
                                    value={elector.mother_name}
                                    variant="standard"
                                />
                            </Item>
                        </Grid>
                    )}
                    <Grid item md={12} p={1}>
                        <Item>
                            <Grid item xs={12}>
                                <VoiceRecorder
                                    ref={this.voiceRecorder}
                                    auto={false}
                                    disabled={
                                        !Boolean(
                                            this.props.elector.phone_number
                                        ) ||
                                        Boolean(this.props.elector?.voted) ||
                                        Boolean(this.state.voting)
                                    }
                                    file={this.state.file}
                                    classes={classes}
                                    onRecord={this.onRecord}
                                    onStateChanged={this.onStateChanged}
                                    onPause={(event) => this.setState(event)}
                                    onMean={this.onMean}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.recording && (
                                    <strong>
                                        {this.state.pause
                                            ? "Paused"
                                            : "Recording"}
                                    </strong>
                                )}
                                {!this.state.recording && this.state.file && (
                                    <strong>Saved</strong>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={
                                    this.state.error
                                        ? classes.red
                                        : classes.green
                                }
                            >
                                {this.state.message}
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item md={4} xs={4} p={1}>
                        <Item>
                            <Button
                                onClick={this.onEnroll}
                                disabled={this.disableEnrol()}
                            >
                                Enroll
                            </Button>
                        </Item>
                    </Grid>
                    <Grid item md={4} xs={4} p={1}>
                        <Item>
                            <Button
                                onClick={this.onAuth}
                                disabled={this.disableAuth()}
                            >
                                Auth
                            </Button>
                        </Item>
                    </Grid>
                    <Grid item md={4} xs={4} p={1}>
                        <Item>
                            <Button
                                onClick={this.onVote}
                                disabled={this.disableVote()}
                            >
                                Vote
                            </Button>
                        </Item>
                    </Grid>
                    {elector.activity && (
                        <Grid
                            item
                            md={12}
                            p={1}
                            style={{overflow: "auto", maxHeight: 400}}
                        >
                            <Item>
                                <Typography variant="body2" align="left">
                                    {(elector.activity || "")
                                        .split("\n")
                                        .map(function (item, index) {
                                            return (
                                                <Fragment key={index}>
                                                    {item}
                                                    <br/>
                                                </Fragment>
                                            );
                                        })}
                                </Typography>
                            </Item>
                        </Grid>
                    )}
                </Grid>
            </Box>
        );
    }
}

export default Elector;