import React from "react";
import axios from "axios";

class RequestSender extends React.Component {
    constructor(props) {
        super(props);
        this.sendData = this.sendData.bind(this);
    }

    async sendData() {
        let fd = new FormData();
        fd.append("user", this.props.user);
        fd.append("file", this.props.file);
        if (this.props.force) {
            fd.append("force", this.props.force);
        }
        try {
            let response = await axios.request({
                url: `/api/${this.props.url}`,
                method: "POST",
                data: fd,
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (typeof this.props.onSuccess === "function") {
                this.props.onSuccess(response);
            }
        } catch (e) {
            console.log(e);
            this.props.onError(e.response);
        }
    }

    render() {
        return <div />;
    }
}

export default RequestSender;