import React from "react";
import {
    Box,
    Button,
    Grid,
    Step,
    StepLabel,
    Stepper,
    TextField,
} from "@mui/material";

import RequestSender from "./RequestSender";
import VoiceRecorder from "./VoiceRecorder";

import { X_AUTH_TOKEN } from "./utils";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            recording: false,
            user: "",
            message: "",
            error: false,
            activeStep: 0,
            speaking: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onStateChanged = this.onStateChanged.bind(this);
        this.onRecord = this.onRecord.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onMean = this.onMean.bind(this);
        this.isValid = this.isValid.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.voiceRecorder = React.createRef();
        this.sender = React.createRef();
        this.steps = [
            {
                label: "Phone number",
                message: "Please enter your phone number",
            },
            {
                label: "First name",
                message: "Please say your first name",
            },
            {
                label: "Last name",
                message: "Please say your last name",
            },
            {
                label: "Address",
                message: "Please say your address",
            },
        ];
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name]: value });
    }

    onNext() {
        let activeStep = this.state.activeStep + 1;
        if (activeStep === this.steps.length) {
            this.voiceRecorder.current.handleStopRecord();
            activeStep = 0;
        }
        this.setState({ activeStep: activeStep, message: "" });
    }

    isDisabled() {
        return (
            (this.state.activeStep === 0 && !Boolean(this.state.user)) ||
            this.state.activeStep >= 3
        );
    }

    onMean(means) {
        const mean = means[0];
        console.log("mean", mean);
        const threshold = this.props.threshold || 1.0;
        if (this.state.speaking && mean < threshold) {
            console.log("Stop speaking...");
            this.setState({ speaking: false, message: "Paused" });
            this.onNext();
        } else if (!this.state.speaking && mean >= threshold) {
            console.log("Start speaking...");
            this.setState({ speaking: true, message: "Recording..." });
        }
    }

    isValid(means) {
        const mean = means[0];
        const threshold = this.props.threshold || 1.0;
        return mean > threshold;
    }

    onRecord(blob) {
        this.setState({ file: blob }, () => {
            if (Boolean(this.state.user) && this.state.file !== null) {
                this.sender.current.sendData().then(() => {
                    console.log("Done.");
                });
            }
        });
    }

    onStateChanged(event) {
        console.log("recorder state : ", event);
        this.setState(event);
    }

    onSuccess(response) {
        let token = response.headers[X_AUTH_TOKEN];
        localStorage.setItem(X_AUTH_TOKEN, token);
        const { message } = response.data;
        this.setState({ error: false, message: message });
    }

    onError(response) {
        console.log(response);
        this.setState({ error: true, message: response.data.message });
    }

    render() {
        const { classes } = this.props;
        return (
            <form>
                <RequestSender
                    ref={this.sender}
                    url={this.props.url}
                    user={`${this.state.user}`}
                    file={this.state.file}
                    onSuccess={this.onSuccess}
                    onError={this.onError}
                />
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    className={classes.form}
                >
                    <Box sx={{ width: "100%" }}>
                        <Stepper activeStep={this.state.activeStep}>
                            <Step>
                                <StepLabel>Phone Number</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>First Name</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Last Name</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Address</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    <Grid item m={2}>
                        <strong>
                            {this.steps[this.state.activeStep].message}
                        </strong>
                    </Grid>
                    {this.state.activeStep === 0 && (
                        <>
                            <Grid item m={1}>
                                <TextField
                                    className={classes.user}
                                    id="user"
                                    name="user"
                                    placeholder={"80000000"}
                                    label="Phone *"
                                    type="phone"
                                    value={this.state.user}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                        </>
                    )}
                    {this.state.activeStep >= 1 && (
                        <>
                            <Grid item m={2}>
                                <VoiceRecorder
                                    ref={this.voiceRecorder}
                                    auto={false}
                                    disabled={!Boolean(this.state.user)}
                                    classes={classes}
                                    onRecord={this.onRecord}
                                    onStateChanged={this.onStateChanged}
                                    onMean={this.onMean}
                                    isValid={this.isValid}
                                />
                            </Grid>
                            <Grid item>
                                {this.state.recording ? (
                                    <strong>Recording...</strong>
                                ) : null}
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        className={this.state.error ? classes.red : null}
                    >
                        {this.state.message}
                    </Grid>
                    <Button onClick={this.onNext} disabled={this.isDisabled()}>
                        Next
                    </Button>
                </Grid>
            </form>
        );
    }
}

export default Register;