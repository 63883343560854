import React from "react"; 

const ACTIVE_WITH_DATA = "/active-with-data.png";
const ACTIVE = "/active.png";
const PAUSE = "/pause-filled.png";
const PLAY = "/play-filled.png";
//const INACTIVE = "/inactive.webp";
const STOP = "/stop.png";
const MICROPHONE_DISABLED = "/microphone-disabled.png";
const { Recorder, AudioContext } = window;

class VoiceRecorder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            pause: false,
        };
        this.handleStartRecord = this.handleStartRecord.bind(this);
        this.handleStopRecord = this.handleStopRecord.bind(this);
        this.onBuffer = this.onBuffer.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleResume = this.handleResume.bind(this);
        if (this.props.auto === true) {
            this.handleStartRecord();
        }
    }

    onBuffer(buffer) {
        console.log("buffer", buffer);
    }

    handleInit() {
        return Promise.resolve(
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    this.stream = stream;
                    this.context = new AudioContext({
                        sampleRate: this.props.sampleRate || 16000,
                    });
                    let input = this.context.createMediaStreamSource(
                        this.stream
                    );
                    console.log(input, new Date());
                    this.recorder = new Recorder(input, {
                        bufferLen: 4096,
                        mimeType: "audio/wav",
                        onBuffer: this.props.onBuffer,
                        onMean: this.props.onMean,
                        isValid: this.props.isValid,
                    });
                })
        );
    }

    pause() {
        this.recorder.stop();
    }

    resume() {
        this.recorder.record();
    }

    handleStartRecord() {
        console.log("starting...", new Date());
        //if (this.props.disabled === true) return;
        console.log("initialization...", new Date());
        this.handleInit().then(() => {
            console.log("initialization... done", new Date());
            this.recorder.record();
            console.log("recording...");
            this.setState({ recording: true }, () => {
                console.log("state updated.", new Date());
                if (typeof this.props.onStateChanged === "function") {
                    this.props.onStateChanged({ recording: true });
                }
            });
            if (this.props.timeout && this.props.timeout > 0) {
                this.timer = setTimeout(() => {
                    this.handleStopRecord();
                }, this.props.timeout);
            }
        });
    }

    handlePause() {
        this.setState({ pause: true }, () => {
            this.pause();
            if (typeof this.props.onPause === "function") {
                this.props.onPause({ pause: true });
            }
        });
    }

    handleResume() {
        this.setState({ pause: false }, () => {
            this.resume();
            if (typeof this.props.onPause === "function") {
                this.props.onPause({ pause: false });
            }
        });
    }

    handleStopRecord() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.recorder.stop();
        this.setState({ recording: false }, () => {
            if (typeof this.props.onStateChanged === "function") {
                this.props.onStateChanged({ recording: false });
            }
        });

        this.stream.getAudioTracks()[0].stop();
        this.recorder.exportWAV((blob) => {
            if (typeof this.props.onRecord === "function") {
                this.props.onRecord(blob);
            }
        });
    }

    componentWillUnmount() {
        if (this.recorder) {
            this.recorder.stop();
            this.recorder.clear();
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                {!this.state.pause && (
                    <img
                        onClick={
                            this.props.disabled === true
                                ? null
                                : this.state.recording
                                ? this.handleStopRecord
                                : this.handleStartRecord
                        }
                        className={classes.record}
                        src={
                            this.props.disabled === true
                                ? MICROPHONE_DISABLED
                                : this.state.recording
                                ? STOP
                                : this.props.file
                                ? ACTIVE_WITH_DATA
                                : ACTIVE
                        }
                        alt={this.state.recording ? "Active" : "Stop"}
                    />
                )}
                {this.state.recording && (
                    <img
                        onClick={
                            this.props.disabled === true
                                ? null
                                : this.state.pause
                                ? this.handleResume
                                : this.handlePause
                        }
                        className={classes.record}
                        src={this.state.pause ? PLAY : PAUSE}
                        alt={this.state.pause ? "Paused" : "Playing"}
                    />
                )}
            </>
        );
    }
}

export default VoiceRecorder;