import axios from "axios";
import React from "react";

import {
    Box,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { Item } from "./utils";



// phone_number, first_name, last_name, father_name, mother_name, vote_place
class ElectorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_number: '',
            first_name: '',
            last_name: '',
            father_name: '',
            mother_name: '',
            vote_place: (props.votePlace || ''),
            message: "",
        };
        this.onValueChange = this.onValueChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onValueChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    init() {
        this.setState({
            phone_number: '',
            first_name: '',
            last_name: '',
            father_name: '',
            mother_name: '',
            vote_place: '',
            message: '',
        });
    }

    isValid() {
        return Boolean(this.state.first_name) && Boolean(this.state.last_name) && Boolean(this.state.phone_number)
    }

    async onSubmit(event) {
        event.preventDefault();
        let {first_name, last_name, phone_number, father_name, mother_name, vote_place} = this.state;
        let data = {first_name, last_name, phone_number, father_name, mother_name, vote_place};
        console.log('data', data);
        try {
            let response = await axios.post("/api/electors", data);
            if (typeof this.props.onSuccess === "function") {
                this.props.onSuccess(response);
                this.init();
            }
        } catch (e) {
            console.log(e);
            let {data} = e.response
            this.setState({message: data.message});
        }
    }

    render() {
        return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid container>
                    <Grid item md={6} p={1}>
                        <Item>
                            <TextField
                                label="Prénom"
                                name="first_name"
                                onChange={this.onValueChange}
                                fullWidth
                                value={this.state.first_name}
                                variant="standard"
                                required
                            />
                        </Item>
                    </Grid>
                    <Grid item md={6} p={1}>
                        <Item>
                            <TextField
                                label="Nom"
                                name="last_name"
                                onChange={this.onValueChange}
                                fullWidth
                                value={this.state.last_name}
                                variant="standard"
                                required
                            />
                        </Item>
                    </Grid>
                    <Grid item md={12} p={1}>
                        <Item>
                        <TextField
                            label="Téléphone"
                            name="phone_number"
                            onChange={this.onValueChange}
                            fullWidth
                            value={this.state.phone_number}
                            variant="standard"
                            required
                        />
                        </Item>
                    </Grid>
                    <Grid item md={6} p={1}>
                        <Item>
                            <TextField
                                label="Père"
                                name="father_name"
                                onChange={this.onValueChange}
                                fullWidth
                                value={this.state.father_name}
                                variant="standard"
                            />
                        </Item>
                    </Grid>
                    <Grid item md={6} p={1}>
                        <Item>
                            <TextField
                                label="Mère"
                                name="mother_name"
                                onChange={this.onValueChange}
                                fullWidth
                                value={this.state.mother_name}
                                variant="standard"
                            />
                        </Item>
                    </Grid>
                    
                    {Boolean(this.state.message) && <Grid item md={12} xs={12} p={1}>
                        <Item>
                            <Typography variant="strong" color={"red"}>{this.state.message}</Typography>
                        </Item>
                    </Grid>}
                    
                    <Grid item md={12} xs={12} p={1}>
                        <Item>
                            <Button
                                onClick={this.onSubmit}
                                disabled={!this.isValid()}
                            >
                                Create
                            </Button>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default ElectorForm;